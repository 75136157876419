




























import { open_export } from "@/shared/utils";
import { Notif } from "@/store";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component({ name: "export-monthly" })
export default class ExportMonthly extends Vue {
  /* Constants */

  @Prop({ required: true }) readonly value!: string;

  /* Variables */

  formData = {
    start_date: null as string | null,
    end_date: null as string | null,
  };

  dateRange = [];

  /* Computed */

  /* Methods */

  @Emit("submit")
  submit(): void {
    try {
      open_export(this.value, this.formData);
      Notif.notif_success("Data Downloaded.");
      this.close();
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  @Emit("input")
  close(): null {
    this.formData = {
      start_date: null,
      end_date: null,
    };
    this.dateRange = [];
    return null;
  }

  /* Life-cycle Methods */

  @Watch("dateRange")
  onDateRangeChange(now: string[]): void {
    if (now.length == 0) return;
    this.formData = {
      start_date: now[0] || null,
      end_date: now[1] || null,
    };
  }
}
